<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container fluid>
    <v-dialog v-model="setDialog" width="400" persistent>
      <v-card>
        <v-card>
          <v-toolbar color="white" dark>
            <v-btn @click="closeDialog">
              <v-icon> mdi-arrow-left</v-icon>
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue" @click="submitConflictOfInterest()">
              <v-icon></v-icon>
              SUBMIT
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-card flat>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="comment*"
                    rows="4"
                    row-height="30"
                    v-model="comment"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "ConlfictOfInterest",
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },
    comment: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      commentText: "",
    };
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    closeDialog() {
      //   this.setDialog = false;
      this.$emit("closeConflictOfInterestDialog", (this.setDialog = false));
    },
    submitConflictOfInterest() {
      this.$emit("submitComment", this.comment);
      this.closeDialog();
    },
  },
};
</script>
