import { eventBus } from "@/utils";

const success = (message, variant = "open") => pop(message, variant);
const info = (message, variant = "info") => pop(message, variant);
const error = (message, variant = "error") => pop(message, variant);
const warn = (message, variant = "warn") => pop(message, variant);

function pop(message, variant) {
  eventBus.$emit("snack-open", { message: message, variant: variant });
}

export { success, info, error, warn };
