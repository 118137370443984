import AuthRoutes from "@/modules/auth/AuthRoutes";
import AuthStore from "@/modules/auth/AuthStore";
import MiddlewareValidation from "@/modules/auth/middleware/MiddlewareValidation";
import Auth from "@/modules/auth/middleware/Auth";
import AuthService from "@/modules/auth/AuthService";
import AuthConstants from "@/modules/auth/AuthConstants";
import store from "@/store";
import router from "@/router";

export default {
  install() {
    router.addRoute(AuthRoutes);
    store.registerModule("Auth", AuthStore);
  },
};

export { MiddlewareValidation, AuthService, Auth, AuthConstants };
