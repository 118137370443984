import appSnack from "@/plugins/snack/appSnack";
import { eventBus } from "@/utils";

export default {
  install(Vue) {
    Vue.component("appSnack", appSnack);

    Vue.prototype.$snack = (payload) => {
      eventBus.$emit("snack-open", payload);
    };
  },
};
