export default {
  login: `auth/login`,
  passwordReset: `auth/password-reset`,
  password: `auth/password`,
  logout: `auth/logout`,
  user: `auth/user`,
  activate: `auth/activate`,
  activate_grant: `auth/activate_grant_reviewer`,
  setCompany: `auth/set-company`,
};
