<template>
  <v-img
    :height="height"
    :src="$store.getters['Landing/company'].logo"
    contain
  />
</template>

<script>
export default {
  name: "appLogo",
  props: {
    height: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style scoped></style>
