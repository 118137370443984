import { appName } from "@/environment";

class AuthService {
  constructor() {
    this.token = window.localStorage.getItem(`${appName}_token`);
    this.user = JSON.parse(window.localStorage.getItem(`${appName}_user`));
    this.company = JSON.parse(
      window.localStorage.getItem(`${appName}_company`)
    );
  }

  check() {
    return !!this.token;
  }

  setUser(user) {
    window.localStorage.removeItem(`${appName}_user`);
    window.localStorage.setItem(`${appName}_user`, JSON.stringify(user));

    window.localStorage.setItem(
      `${appName}_company`,
      JSON.stringify(user.company)
    );

    this.user = user;
    this.company = user.company;
    location.reload();
  }

  setCompany(company) {
    window.localStorage.removeItem(`${appName}_company`);
    window.localStorage.setItem(`${appName}_company`, JSON.stringify(company));
    this.company = company;
    location.reload();
  }

  login(token, user) {
    window.localStorage.setItem(`${appName}_token`, token);
    window.localStorage.setItem(`${appName}_user`, JSON.stringify(user));
    window.localStorage.setItem(
      `${appName}_company`,
      JSON.stringify(user.company)
    );

    this.token = token;
    this.user = user;
    this.company = user.company;

    location.reload();
  }

  logout() {
    window.localStorage.removeItem(`${appName}_token`);
    window.localStorage.removeItem(`${appName}_user`);
    window.localStorage.removeItem(`${appName}_company`);

    window.location.href = "/";
  }
}

export default new AuthService();
