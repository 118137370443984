class ReviewDocument {
  constructor(doc = {}) {
    for (const key in doc) this[key] = doc[key];
  }

  approve() {}

  hold() {}

  decline() {}
}

export default ReviewDocument;
