<template>
  <snack-bar
    ref="snacker"
    baseSize="100px"
    :holdTime="10000"
    :multiple="true"
    :position="position"
    :colors="snackColors"
  />
</template>

<script>
import snackBar from "vuejs-snackbar";
import { eventBus } from "@/utils";
import colors from "vuetify/lib/util/colors";

export default {
  name: "appSnack",
  components: { snackBar },

  data: function () {
    return {
      position: "",
    };
  },

  computed: {
    snackColors() {
      return {
        open: colors.green.base,
        info: colors.blue.base,
        error: colors.red.accent2,
        warn: colors.amber.base,
      };
    },
  },

  mounted() {
    eventBus.$on("snack-open", ({ message, variant, position }) => {
      this.position = position || "top-center";

      switch (variant.toLowerCase().trim()) {
        case "info":
          this.$refs.snacker.info(message);
          break;
        case "error":
          this.$refs.snacker.error(message);
          break;
        case "warn":
          this.$refs.snacker.warn(message);
          break;
        case "open":
        default:
          this.$refs.snacker.open(message);
          break;
      }
    });
  },
};
</script>

<style scoped></style>
