<template>
  <v-app>
    <!--  Side Bar  -->
    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant="$vuetify.breakpoint.mdAndUp ? mini : false"
      app
      color="aside"
      dark
      v-model="drawer"
      class="fill-height"
    >
      <v-card flat color="aside" class="pa-2" width="100%">
        <logo :height="50" />

        <v-card-subtitle v-if="!mini" class="overline text-center pa-0">
          {{ company.displayName }}
        </v-card-subtitle>
      </v-card>

      <v-divider></v-divider>

      <v-list dense>
        <div v-for="(item, i) in links" :key="i">
          <v-list-group
            v-if="item.sublinks.length > 0"
            v-model="item.active"
            active-class="font-weight-bolder white--text"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group>
              <v-list-item
                dense
                link
                :to="sub.to"
                v-for="(sub, s) in item.sublinks"
                :key="s"
                active-class="white--text"
              >
                <v-list-item-avatar height="0" v-if="!mini">
                  &nbsp;
                </v-list-item-avatar>
                <v-list-item-icon class="mr-2">
                  <v-icon small v-if="!mini">
                    {{ sub.icon }}
                  </v-icon>

                  <v-tooltip v-else left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">{{ sub.icon }}</v-icon>
                    </template>
                    <span> {{ sub.title }}</span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ sub.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="sub.value">
                  <v-chip x-small color="success">
                    {{ sub.value }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <v-list-item-group v-else>
            <v-list-item :to="item.to">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!--  App Bar  -->
    <v-app-bar app elevation="0">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndUp"
        @click="mini = !mini"
      ></v-app-bar-nav-icon>

      <v-breadcrumbs :items="crumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>

        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            class="text-capitalize"
            disabled
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-spacer />

      <v-btn rounded icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <notification />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded text v-bind="attrs" v-on="on">
            {{ user.name }}
            <v-icon right>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list dense tile>
          <v-list-item :to="{ name: 'setting' }">
            <v-list-item-title>
              <v-icon>mdi-cog</v-icon>
              Setting
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="$store.dispatch('Auth/logout')">
            <v-list-item-title>
              <v-icon>mdi-export</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-divider />

    <v-main class="background">
      <router-view class="my-3" />
    </v-main>

    <app-loader />

    <app-confirmation />

    <app-snack />

    <app-viewer />

    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/appFooter";
import { AuthService } from "@/modules/auth";
import Logo from "@/components/appLogo";
import Notification from "@/components/appNotification";
// import { appName, isGrantReviewPortal, multiCompany } from "@/environment";

export default {
  name: "dashboardLayout",
  components: { Notification, Logo, Footer },
  data: () => ({
    drawer: null,
    mini: false,
    selectedItem: 0,
  }),
  computed: {
    crumbs() {
      let pathArray = this.$route.path.split("/");

      pathArray.shift();

      return pathArray
        .reduce((breadcrumbArray, path, idx) => {
          breadcrumbArray.push({
            path: path,
            href: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: this.$route.matched[idx]
              ? this.$route.matched[idx].name
              : path,
          });
          return breadcrumbArray;
        }, [])
        .filter((p) => p.text);
    },

    user() {
      return AuthService.user;
    },

    company() {
      return this.$store.getters["Landing/company"];
    },

    links() {
      // console.log(this.$store.getters["Dashboard/links"]);
      // console.log(isGrantReviewPortal, appName, multiCompany);
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled)
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },
  },
};
</script>

<style scoped></style>
