import { render, staticRenderFns } from "./appSuccess.vue?vue&type=template&id=60a8f975&scoped=true&"
import script from "./appSuccess.vue?vue&type=script&lang=js&"
export * from "./appSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a8f975",
  null
  
)

export default component.exports