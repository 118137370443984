<template>
  <v-app>
    <v-app-bar app dark color="primary">
      <v-img
        v-if="company.logo"
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="`data:image/png;base64,${company.logo}`"
        transition="scale-transition"
        width="50"
        @click="$router.push({ name: 'Landing' })"
      />

      <v-btn v-else @click="$router.push({ name: 'Landing' })" text>
        Cardinal
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="auth.check()"
        @click="$store.dispatch('Auth/logout')"
        text
        class="ml-2"
        color="white"
        light
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <spinner />

    <Footer opacity="0.65" />
  </v-app>
</template>

<script>
import Footer from "../../../components/appFooter";
import Spinner from "@/plugins/loader/views/appSpinner";
import { AuthService } from "../index";
import { RouterMixin } from "@/mixins";

export default {
  name: "AuthLayout",
  mixins: [RouterMixin],
  components: { Spinner, Footer },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$router.push({ name: "Application Summary" });
      }
    });
  },

  computed: {
    auth() {
      return AuthService;
    },

    company() {
      return this.$store.getters["Landing/company"];
    },
  },

  watch: {
    "$route.name": {
      handler: function () {
        this.$store.commit("Auth/SET_ALERT", null);
      },
    },
  },
};
</script>

<style scoped></style>
