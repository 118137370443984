import dashboardRoutes from "./dashboardRoutes";
import dashboardStore from "./dashboardStore";
import store from "@/store";
import router from "@/router";

export default {
  install() {
    router.addRoute(dashboardRoutes);
    store.registerModule("Dashboard", dashboardStore);
  },
};
