<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>Attachments</v-card-title>

    <v-divider />

    <v-card-text class="pa-10">
      <v-alert
        type="error"
        dense
        text
        icon="mdi-cloud-alert"
        border="left"
        v-if="attachmentError"
      >
        Each attachment should be less than 2MB
      </v-alert>

      <div>
        <div class="border" @click="$refs.uploader.$refs.input.click()">
          <div class="mx-auto d-flex">
            <v-icon :size="40" class="mr-3"> mdi-cloud-upload-outline </v-icon>

            <div class="mt-10">
              <span>Click here to add files</span>
            </div>

            <v-file-input
              accept="application/pdf,.jpeg,.png,.jpg,GIF"
              ref="uploader"
              hide-input
              prepend-icon=""
              @change="uploadFile"
            />
          </div>
        </div>

        <div v-if="formData.base64Attachment">
          <v-list-item>
            <v-list-item-icon>
              <v-chip label color="blue lighten-4" small>
                <v-icon small color="blue">mdi-file-outline</v-icon>
              </v-chip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex">
                  {{ formData.fileName }}

                  <v-icon small color="error" @click="deleteFile" class="ml-5">
                    mdi-delete
                  </v-icon>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="toggleDialog">cancel</v-btn>
      <v-btn text color="success" @click="submit">submit</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import helper from "@/utils/helpers/helper";
export default {
  name: "AttachmentUploader",
  props: ["toggleDialog"],
  data() {
    return {
      formData: {
        applicationCode: this.$route.params.Code,
        fileName: "",
        base64Attachment: "",
      },
      attachmentError: false,
      loading: false,
    };
  },
  methods: {
    async uploadFile(value) {
      if (value) {
        if (value.size > 2000000) {
          this.attachmentError = true;
        } else {
          this.formData.fileName = value.name;
          this.formData.base64Attachment = await helper.toBase64(value);
          this.formData.base64Attachment =
            this.formData.base64Attachment.split(",")[1];
          this.attachmentError = false;
        }
      }
    },
    deleteFile() {
      this.formData = {
        applicationCode: "",
        base64Attachment: "",
      };
    },
    submit: function () {
      if (!this.$refs.uploader.validate()) return;

      this.loading = true;

      console.log(this.formData, this.$route.params.Code);
      this.$store
        .dispatch("Application/setUniversityResponse", this.formData)
        .then(() => {
          this.loading = false;
          this.toggleDialog();
        });
    },
  },
};
</script>
<style>
@import "../styles.css";
</style>
