import store from "@/store";
import router from "@/router";
import links from "./links";
import grantRoutes from "./grantRoutes";
import grantStore from "./grantStore";

export default {
  install() {
    router.addRoute("Dashboard", grantRoutes);

    store.registerModule("Grants", grantStore);
    store.commit("Dashboard/SET_LINKS", links);
  },
};
