import { isGrantReviewPortal } from "@/environment";

export default {
  to: "/dashboard/grants",
  order: 2,
  icon: "mdi-gift",
  title: "Review Grants",
  disabled: JSON.parse(isGrantReviewPortal) ? false : true,
  sublinks: [
    {
      to: "/dashboard/grant_applications/list/assigned",
      order: 1,
      icon: "mdi-arrow-split-vertical",
      title: "Grant Applications",
      disabled: false,
    },
  ],
};
