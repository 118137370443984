import landingRoutes from "./landingRoutes";
import landingStore from "./landingStore";
import store from "@/store";
import router from "@/router";

export default {
  install() {
    router.addRoute(landingRoutes);
    store.registerModule("Landing", landingStore);
  },
};
