<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded v-bind="attrs" v-on="on" icon>
        <v-badge
          :content="notification.messages.length + notification.alerts.length"
          :value="notification.messages.length + notification.alerts.length"
          dot
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card min-width="350">
      <v-tabs grow>
        <v-tab>
          Alerts
          <v-badge
            :content="notification.alerts.length"
            :value="notification.alerts.length"
            color="warning"
            overlap
          >
            <v-icon right>mdi-bell</v-icon>
          </v-badge>
        </v-tab>
        <v-tab>
          Messages
          <v-badge
            :content="notification.messages.length"
            :value="notification.messages.length"
            color="warning"
            overlap
          >
            <v-icon right>mdi-email</v-icon>
          </v-badge>
        </v-tab>

        <v-tab-item>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                two-line
                v-for="(alert, i) in notification.alerts"
                :key="i"
                :to="{ name: alert.action }"
              >
                <v-list-item-action>
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ alert.title }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ alert.message }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tab-item>

        <v-tab-item>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                two-line
                v-for="(message, i) in notification.messages"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ message.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    message.message
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="caption">
                  {{ message.time }}
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "appNotification",
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    notification: {
      alerts: [],
      messages: [],
    },
  }),
  mounted() {
    // listen to socket notification channel
    // this.$echo.channel("notification").listen(".alert", (payload) => {
    //   this.notification.alerts.push(payload);
    // });
    //
    // this.$echo.channel("notification").listen(".message", (payload) => {
    //   this.notification.messages.push(payload);
    // });
  },
};
</script>

<style scoped></style>
