<template>
  <v-app>
    <v-app-bar app elevation="24">
      <v-img
        v-if="company.logo"
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="`data:image/png;base64,${company.logo}`"
        transition="scale-transition"
        width="50"
        @click="$router.push({ name: 'Landing' })"
      />

      <v-btn v-else @click="$router.push({ name: 'Landing' })" text>
        Review
      </v-btn>

      <v-spacer />
      <v-btn
        v-if="isGrantReviewPortal"
        class="mr-2"
        color="blue"
        :to="{ name: 'activate' }"
        >Activate account</v-btn
      >
      <v-btn color="primary" :to="{ name: 'login' }">Login</v-btn>
    </v-app-bar>
    <v-main>
      <v-container
        fluid
        class="pa-0"
        style="
          background-size: cover;
          background: url('https://images.unsplash.com/photo-1624557446133-bd61df5a0e74?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')
            no-repeat center center;
        "
      >
        <v-sheet
          class="py-16"
          style="background-color: rgba(4, 4, 4, 0.57); min-height: 50vh"
        >
          <v-row>
            <v-col cols="10" offset="1" md="6" offset-md="3" class="my-5">
              <h4
                class="text-h3 white--text text-center font-weight-bold my-10"
              >
                {{
                  isGrantReviewPortal
                    ? "GRANT APPLICATIONS REVIEW"
                    : "APPLICATIONS REVIEW"
                }}
              </h4>
              <h3 class="text-h5 white--text text-center">
                {{
                  isGrantReviewPortal
                    ? "Review grant applications"
                    : " Review membership, licence and upgrade applications"
                }}
              </h3>
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>

      <v-container fluid class="py-16">
        <v-row>
          <v-col cols="2" offset="5">
            <v-divider dark />
          </v-col>

          <v-col cols="12" class="mb-5 text-center">
            <h1 class="font-weight-bold text-h4 capitalize">
              <span class="primary--text">AVAILABLE MODULES</span>
            </h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10" offset="1">
            <v-row class="justify-center">
              <v-col
                md="4"
                cols="12"
                class="d-flex"
                v-for="(item, i) in items.filter((item) =>
                  isGrantReviewPortal
                    ? item.name === 'GRANTS'
                    : item.name !== 'GRANTS'
                )"
                :key="i"
              >
                <v-hover
                  v-slot="{ hover }"
                  open-delay="100"
                  style="width: 100%"
                >
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    class="py-10"
                  >
                    <v-card-text class="text-center">
                      <div style="min-height: 150px">
                        <v-icon color="secondary" x-large>
                          {{ item.icon }}</v-icon
                        >

                        <h3 class="text-uppercase display-5 primary--text my-4">
                          {{ item.name }}
                        </h3>
                        <p class="">{{ item.description }}</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-main>
        <v-sheet dark min-height="30vh" class="pa-10" color="grey darken-4">
          <v-row>
            <v-col cols="12" md="6" offset-md="3">
              <v-img
                class="mb-5"
                src="img/logo-white.png"
                max-height="220"
                max-width="220"
                contain
                @click="$router.push('/')"
              ></v-img>

              <p class="pa-2 my-2 text-center">
                <v-icon small class="mr-2">mdi-phone</v-icon>
                +257 782757774
              </p>

              <p class="pa-2 my-2 text-center">
                <v-icon small class="mr-2">mdi-email</v-icon>
                solutions@kinetics.co.ke
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="text-center">
                Terms of Use, Disclaimer, Privacy Policy.
              </p>
            </v-col>
          </v-row>
        </v-sheet>
      </v-main>
    </v-main>
  </v-app>
</template>

<script>
import { isGrantReviewPortal } from "@/environment";

export default {
  name: "appLanding",
  data() {
    return {
      isGrantReviewPortal: JSON.parse(isGrantReviewPortal),
    };
  },
  mounted() {
    console.log(this.isGrantReviewPortal);
  },
  computed: {
    company() {
      return this.$store.getters["Landing/company"];
    },

    items() {
      return [
        {
          name: "APPLICATIONS",
          icon: "mdi-account-check",
          description: "Review new membership applications",
        },
        {
          name: "UPGRADES",
          icon: "mdi-account-arrow-up",
          description: "Review member upgrade requests",
        },
        {
          name: "LICENCES",
          icon: "mdi-certificate",
          description: "Review licences applications",
        },
        {
          name: "GRANTS",
          icon: "mdi-grant",
          description: "Review grants applications",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
