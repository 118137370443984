import colors from "vuetify/lib/util/colors";

const HasChart = {
  methods: {
    getChartData: function (data) {
      const chartData = {
        labels: [],
        data: [],
        backgroundColor: [],
      };
      for (const key in data) {
        chartData.labels.push(key);
        chartData.data.push(data[key]);
      }

      chartData.backgroundColor = this.colors
        .sort(() => Math.random() - 0.5)
        .slice(0, data.length);

      return chartData;
    },

    getChartOptions: function (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        pieceLabel: {
          mode: "percentage",
          precision: 1,
        },
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: title,
          },
        },
      };
    },

    randomColor: function (variant = "base", hex = true) {
      let keys = Object.keys(colors);

      if (!hex) return keys[(keys.length * Math.random()) << 0];

      return colors[keys[(keys.length * Math.random()) << 0]][variant];
    },
  },
  computed: {
    colors() {
      return [
        "#706fd3",
        "#455A64",
        "#ff5252",
        "#ff793f",
        "#5D4037",
        "#34ace0",
        "#33d9b2",
        "#ffda79",
      ];
    },
  },
};
export default HasChart;
