<template>
  <v-footer app :inset="true" elevation="12" class="overline">
    <v-menu top nudge-top="30" tile v-if="multiCompany">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          tile
          v-bind="attrs"
          text
          v-on="on"
          x-small
          :disabled="companies.length === 0"
        >
          {{ company ? company.name : "Company" }}
          <v-icon right>mdi-chevron-up</v-icon>
        </v-btn>
      </template>
      <v-list dense tile>
        <v-list-item
          v-for="(company, i) in companies"
          :key="i"
          @click="setCompany(company)"
        >
          <v-list-item-title>
            {{ company.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer v-if="multiCompany" />

    <span style="font-size: 0.625rem">
      {{ appName }} © {{ new Date().getFullYear() }}
    </span>

    <v-spacer />

    <v-btn x-small text href="https://kinetics.co.ke/" target="_blank">
      Kinetics Technology LTD
      <v-icon x-small right> mdi-open-in-new </v-icon>
    </v-btn>
  </v-footer>
</template>

<script>
import { appName, multiCompany } from "@/environment";
import { AuthService } from "@/modules/auth";

export default {
  name: "appFooter",
  props: {
    opacity: {
      type: String,
      default: "1",
    },
  },

  computed: {
    appName() {
      return appName;
    },

    multiCompany() {
      return multiCompany === "true";
    },

    company() {
      return AuthService.company;
    },

    companies() {
      return this.$store.getters["Landing/companies"];
    },
  },

  methods: {
    setCompany: function (company) {
      if (AuthService.check()) {
        this.$store.dispatch("Auth/setCompany", {
          default_company: company.id,
        });
      } else {
        AuthService.setCompany(company);
      }
    },
  },
};
</script>

<style scoped></style>
