import DashboardLayout from "./views/dashboardLayout";
import Auth from "../auth/middleware/Auth";
import dashboardSummaries from "@/modules/dashboard/components/dashboardSummaries";
import applicationDashboard from "@/modules/dashboard/components/applicationDashboard";

export default {
  path: "/dashboard",
  component: DashboardLayout,
  name: "Dashboard",
  children: [
    {
      path: "summary",
      component: dashboardSummaries,
      children: [
        {
          path: "",
          name: "Summary",
          redirect: "/dashboard/summary/application",
        },
        {
          path: "application",
          name: "Application Summary",
          component: applicationDashboard,
          meta: { middleware: [Auth], name: "Application" },
        },
      ],
    },
  ],
};
