import AuthLayout from "./views/AuthLayout";
import Login from "./components/appLogin";
import Success from "./components/appSuccess";
import Verify from "./components/appVerify";
import ForgotPassword from "./components/appForgotPassword";
import Password from "./components/appPassword";
import appActivate from "@/modules/auth/components/appActivate";

export default {
  path: "/auth",
  component: AuthLayout,
  children: [
    {
      path: "",
      redirect: "/auth/login",
    },
    {
      path: "login",
      name: "login",
      component: Login,
    },
    {
      path: "forgot-password",
      name: "forgotPassword",
      component: ForgotPassword,
    },
    {
      path: "password/:code",
      name: "setPassword",
      component: Password,
    },
    {
      path: "success",
      name: "success",
      component: Success,
    },
    {
      path: "activate",
      name: "activate",
      component: appActivate,
    },
    {
      path: "verify/:code",
      name: "verify",
      component: Verify,
    },
  ],
};
