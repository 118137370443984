import { request } from "@/service";
import landingconstants from "@/modules/landing/landingconstants";
import { appName } from "@/environment";

export default {
  namespaced: true,
  state: {
    companies: [],
    company: {
      displayName: appName,
      logo: "",
    },
  },
  mutations: {
    SET_COMPANY_INFO: (state, payload) => {
      state.company = payload;
    },

    SET_COMPANIES: (state, payload) => {
      state.companies = payload;
    },
  },
  getters: {
    company: (state) => state.company,
    companies: (state) => state.companies,
  },
  actions: {
    getCompanyInformation({ commit }) {
      request("get", landingconstants.companyInfo)
        .then((res) => {
          commit("SET_COMPANY_INFO", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCompanies: ({ commit }) => {
      request("get", landingconstants.companies)
        .then((res) => {
          commit("SET_COMPANIES", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
