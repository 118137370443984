import appViewer from "@/plugins/viewer/appViewer";
import { eventBus } from "@/utils";

export default {
  install(Vue) {
    Vue.component("appViewer", appViewer);

    Vue.prototype.$viewer = (payload) => {
      eventBus.$emit("open-viewer", payload);
    };
  },
};
