import routes from "./upgradeRoutes";
import upgradeStore from "./upgradeStore";

import links from "./links";
import store from "@/store";
import router from "@/router";

export default {
  install() {
    router.addRoute("Dashboard", routes);

    store.registerModule("Upgrade", upgradeStore);
    store.commit("Dashboard/SET_LINKS", links);
  },
};
