import virtualView from "@/router/virtualView";
import grantsList from "./views/grantsList";
import GrantApplicationCard from "./components/GrantApplicationCard";

export default {
  path: "grant_applications",
  component: virtualView,
  children: [
    {
      path: "",
      name: "grantsReview",
      redirect: "grant_applications/list/:cue",
    },
    {
      path: "list/:cue",
      name: "grantList",
      component: grantsList,
    },
    {
      path: "list/:applicationId",
      name: "reviewSingleApplication",
      component: GrantApplicationCard,
    },
  ],
};
