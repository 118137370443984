import { render, staticRenderFns } from "./appVerify.vue?vue&type=template&id=446b63ee&scoped=true&"
import script from "./appVerify.vue?vue&type=script&lang=js&"
export * from "./appVerify.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446b63ee",
  null
  
)

export default component.exports