import { request as call } from "@/service";
import { AuthConstants } from "@/modules/auth";
import AuthService from "./AuthService";

export default {
  namespaced: true,

  state: {
    alert: {
      status: "",
      message: "",
    },
  },

  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },

  getters: {
    alert: (state) => state.alert,
  },

  actions: {
    login: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.login, data)
        .then((res) => {
          AuthService.login(res.data.data.token, res.data.data.user);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    setCompany: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.setCompany, data)
        .then((res) => {
          AuthService.setUser(res.data.data);
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    forgotPassword: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.passwordReset, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: res.data.data,
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    activate: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.activate, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: res.data.data,
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    activate_grant_reviewer: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.activate_grant, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: res.data.data,
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    resetPassword: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.password, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          AuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    user: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", AuthConstants.user).then((res) => {
        AuthService.setUser(res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    logout: () => {
      call("get", AuthConstants.logout).then(() => {
        AuthService.logout();
      });
    },
  },
};
