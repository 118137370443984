import confirmation from "./appConfirmation";
import { eventBus } from "../../utils";

const ConfirmPlugin = {
  install(Vue) {
    Vue.component("appConfirmation", confirmation);

    Vue.prototype.$confirm = (params) => {
      eventBus.$emit("show", params);
    };
  },
};

export default ConfirmPlugin;
