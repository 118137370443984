import client from "./client/client";

export default async function request(
  requestType,
  url,
  data = null,
  headers = null
) {
  return client[requestType](url, data, headers ? { headers } : {});
}
