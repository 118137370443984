import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [],
});

export default new Vuex.Store({
  state: {
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
