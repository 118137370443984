function appendQueryString(url, params) {
  let queryUrl = new URL("http://tempurl/");

  for (let key in params) {
    queryUrl.searchParams.append(key, params[key]);
  }
  queryUrl = queryUrl.href.replace("http://tempurl/", url);

  return queryUrl;
}

function appendQueryParam(url, param) {
  return `${url}/${param}`;
}

function crud(path) {
  return {
    list: (query) => appendQueryString(path, query),
    create: path,
    show: (param, query) =>
      appendQueryString(appendQueryParam(path, param), query),
    patch: (param) => appendQueryParam(path, param),
    destroy: (param) => appendQueryParam(path, param),
  };
}

export { appendQueryString, appendQueryParam, crud };
